import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";


const SidebarMainNavbar = (props) => {
  return (
    <div className="main-navbar">
      <Navbar
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        type="light"
      >
        <NavbarBrand
          className="w-100 mr-0"
          href="#"
          style={{ lineHeight: "25px" }}
        >
          <div className="d-table m-auto">
            <img
              id="main-logo"
              className="d-inline-block align-top mr-1"
              style={{ maxWidth: "25px" }}
              src={require("../../../images/logo.svg")}
              alt="Um bichinho"
            />
            <span className="d-none d-md-inline ml-1">
              um bichinho
            </span>
          </div>
        </NavbarBrand>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
          onClick={props.toggleSideBar}
        >
          <i className="material-icons">&#xE5C4;</i>
        </a>
      </Navbar>
    </div>
  );
}

SidebarMainNavbar.propTypes = {
  toggleSideBar: PropTypes.func
};

export default SidebarMainNavbar;
