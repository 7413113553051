// Layout Types
import { DefaultLayout } from "./layouts";

// Views
import LostAnimals from "./views/LostAnimals";


export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: LostAnimals
  },
];
