import React, { useContext } from "react";
import classNames from "classnames";
import { Col } from "shards-react";
import LocationContext from "../../contexts/location";


const LocationDisplay = ({ className, ...attrs }) => {
  const context = useContext(LocationContext);

  const classes = classNames(
    className,
    "text-center",
  );

  return (
    <Col xs="12" className={classes} { ...attrs }>
      {context.selectedLocation && (
        <h6 className="pt-2">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          Anúncios em <a href="#" onClick={context.toggleModal} className="border-bottom">
            { context.selectedLocation.verbose }
          </a>
        </h6>
      )}
    </Col>
  )
};


export default LocationDisplay;
