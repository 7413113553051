import React from 'react';
import PropTypes from "prop-types";

import AsyncSelect from 'react-select/async';
import _ from "lodash";

const SEARCH_URI = 'https://brazilian-cities-api.herokuapp.com/cities';

const LocationSearchInput = (props) => {
  const handleSearch = (query, callback) => {
    fetch(`${SEARCH_URI}?q=${query}`)
      .then((resp) => resp.json())
      .then((data) => {
        const options = data.map((location) => ({
          id: String(location.id),
          verbose: location.verbose,
        }));

        callback(options);
      });
  };

  const debounceHandleSearch = _.debounce(handleSearch, 300);

  return (
    <AsyncSelect
      isClearable={true}
      autoFocus={true}
      getOptionLabel={option => option.verbose}
      getOptionValue={option => option.value}
      loadOptions={debounceHandleSearch}
      cacheOptions={true}
      onChange={(value) => props.onChange(value)}
      placeholder="Digite o nome de sua cidade..."
      loadingMessage={() => "Buscando sua cidade..."}
      noOptionsMessage={() => "Nenhuma cidade encontrada"}
    />
  );
};

LocationSearchInput.propTypes = {
  onChange: PropTypes.func
};

export default LocationSearchInput;