import React, { useContext } from "react";

import SideBarContext from "../../../contexts/sidebar";


const NavbarToggle = () => {
  const context = useContext(SideBarContext);

  return (
    <nav className="nav">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" onClick={ context.toggleSideBar } className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center">
        <i className="material-icons">&#xE5D2;</i>
      </a>
    </nav>
  )
}

export default NavbarToggle;
