import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";


const SidebarNavItems = () => {
  const items = [{
    title: "Perdidos",
    to: "/",
    htmlBefore: '<i class="material-icons">wrong_location</i>',
  }]

  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {items.map((item, idx) => (
          <SidebarNavItem key={idx} item={item} />
        ))}
      </Nav>
    </div>
  )
}

export default SidebarNavItems;
