import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";
import { NavbarBrand } from "shards-react";

import NavbarToggle from "./NavbarToggle";

const MainNavbar = ({ stickyTop }) => {
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  return (
    <div className={classes}>
      <Container className="p-0">
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0 d-lg-none">
          <NavbarBrand
              href="/"
              style={{ lineHeight: "25px" }}
            >
            <div className="d-2 m-2">
              <img
                id="main-logo"
                className="d-inline-block align-top"
                style={{ maxWidth: "25px" }}
                src={require("../../../images/logo.svg")}
                alt="Um bichinho"
              />
              <span className="d-md-inline ml-1">
                Um bichinho
              </span>
            </div>
          </NavbarBrand>

          <NavbarToggle />
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
