import React, { createContext, useState } from 'react';

const SideBarContext = createContext({
  isOpen: false,
  toggleSideBar: () => {},
});

export default SideBarContext;

export const SideBarProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const state = {
    isOpen: isOpen,
    toggleSideBar: () => {
      setIsOpen(!isOpen)
    }
  }

  return (
    <SideBarContext.Provider value={state}>
      { props.children }
    </SideBarContext.Provider>
  )
}