import React, { createContext, useState } from 'react';

const LocationContext = createContext({
  selectedLocation: null,
  setLocation: () => null,
  isModalOpen: false,
  toggleModal: () => null
});

export default LocationContext;

export const LocationProvider = (props) => {
  const [selectedLocation, setLocation] = useState(null);
  const [isModalOpen, toggleModal] = useState(selectedLocation ? false: true);

  const state = {
      selectedLocation: selectedLocation,
      setLocation: setLocation,
      isModalOpen: isModalOpen,
      toggleModal: () => {
        toggleModal(!isModalOpen)
      }
  }

  return (
    <LocationContext.Provider value={state}>
      { props.children }
    </LocationContext.Provider>
  )
}