import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/shards-dashboards.1.1.0.min.css";

import { SideBarProvider } from "./contexts/sidebar";
import { LocationProvider } from "./contexts/location";

function Context(props) {
  return (
    <SideBarProvider>
      <LocationProvider>
        {props.children}
      </LocationProvider>
    </SideBarProvider>
  )
}

export default () => (
  <Router>
    <div>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <Context>
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                </Context>
              );
            })}
          />
        );
      })}
    </div>
  </Router>
);
