import React, { useContext } from "react";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";
import SideBarContext from "../../../contexts/sidebar";


const MainSidebar = () => {
  const context = useContext(SideBarContext);
  return (
    <Col
      tag="aside"
      className={classNames(
        "main-sidebar",
        "px-0",
        "col-12",
        context.isOpen && "open"
      )}
      lg={{ size: 2 }}
      md={{ size: 3 }}
    >
      <SidebarMainNavbar toggleSideBar={context.toggleSideBar} />
      <SidebarNavItems />
    </Col>
  )
}

export default MainSidebar;
