import React, { useContext, useState } from "react"
import { Modal, ModalBody, ModalFooter, Button } from "shards-react";

import LocationContext from "../../contexts/location";
import LocationSearchInput from "./LocationSearchInput";


const LocationModal = () => {
    const context = useContext(LocationContext);
    const [location, setLocation] = useState(null);

    const onLocationChange = (location) => {
      setLocation(location);
    }

    const handleSubmit = () => {
      if (location) {
        context.setLocation(location);
        context.toggleModal();
      }
    }

    return (
      // This modal should be kept open while we do not set a location
      <Modal centered={true} open={context.isModalOpen} toggle={() => null}>
        <ModalBody>
          <h5>Temos conteúdo exclusivo dependendo da sua localização</h5>
          <LocationSearchInput onChange={onLocationChange} />
        </ModalBody>
        <ModalFooter>
          <Button block disabled={!location} pill theme="success" onClick={handleSubmit}>
            Salvar
          </Button>
        </ModalFooter>
      </Modal>
    )
}

export default LocationModal;